import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl, offsetLimitMergeResults, offsetLimitSerializeQueryArgs } from "../utils";

export const providersApi = createApi({
  reducerPath: "providersApi",
  baseQuery: baseApiQuery(),
  tagTypes: ["Providers", "Provider"],
  endpoints: (builder) => ({
    getProviderProfile: builder.query<any, string>({
      query: (provider_id) => `/api/providers-profiles/public/search/${provider_id}`,
      providesTags: ["Provider"],
    }),
    getProviderMeProfile: builder.query<any, void>({
      query: () => `/api/providers-profiles/public/search/me`,
      providesTags: ["Provider"],
    }),
    getProviderRatings: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-ratings/public/bookings`, queryParams),
      serializeQueryArgs: offsetLimitSerializeQueryArgs,
      providesTags: ["Provider"],
    }),
  }),
});
export const { useGetProviderProfileQuery, useGetProviderRatingsQuery, useGetProviderMeProfileQuery } = providersApi;
