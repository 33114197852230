import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const customersApi = createApi({
  reducerPath: "customersApi",
  baseQuery: baseApiQuery(),
  tagTypes: [
    "AllocationStats",
    "Allocations",
    "AllocationsQuota",
    "AvailabilityRequestsSettings",
    "CustomerAvailabilityRequests",
  ],
  endpoints: (builder) => ({
    // Allocation
    getAllocationStats: builder.query<any, void>({
      query: () => `/api/providers-customers/providers/customer-allocations/stats`,
      providesTags: ["AllocationStats"],
    }),
    getCountAllocations: builder.query<any, void>({
      query: () => `/api/providers-customers/providers/customer-allocations/count`,
      providesTags: ["AllocationStats"],
    }),
    allocationsSearch: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-customers/providers/customer-allocations`, queryParams),
      providesTags: ["Allocations"],
    }),
    getAllocatedCustomerFullDetails: builder.query<any, string>({
      query: (customer_id) => `/api/providers-customers/providers/customer-allocations/${customer_id}/detailed`,
    }),
    getAllocatedCustomerContactDetails: builder.query<any, string>({
      query: (customer_id) => `/api/providers-customers/providers/customer-allocations/${customer_id}/contact`,
    }),
    getAllocationQuota: builder.query<any, void>({
      query: () => `/api/providers-customers/providers/allocation-availability`,
      providesTags: ["AllocationsQuota"],
    }),
    updateAllocationsQuota: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-customers/providers/allocation-availability`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AllocationsQuota"],
    }),
    getAllocationAvailability: builder.query<any, void>({
      query: () => `/api/providers/me/availability-requests`,
      providesTags: ["AvailabilityRequestsSettings"],
    }),
    updateAllocationAvailability: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/me/availability-requests`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AvailabilityRequestsSettings"],
    }),
    getAllocationRequest: builder.query<any, string>({
      query: (customer_id) => `/api/providers-customers/providers/allocation-requests/${customer_id}`,
      providesTags: ["CustomerAvailabilityRequests"],
    }),
    acceptAllocationAvailabilityRequest: builder.mutation<any, any>({
      query: ({ customer_id }) => ({
        url: `/api/providers-customers/providers/allocation-requests/${customer_id}/accept`,
        method: "POST",
      }),
      invalidatesTags: ["CustomerAvailabilityRequests"],
    }),
    declineAllocationAvailabilityRequest: builder.mutation<any, any>({
      query: ({ customer_id }) => ({
        url: `/api/providers-customers/providers/allocation-requests/${customer_id}/reject`,
        method: "POST",
      }),
      invalidatesTags: ["CustomerAvailabilityRequests"],
    }),
  }),
});
export const {
  useGetAllocationStatsQuery,
  useAllocationsSearchQuery,
  useLazyAllocationsSearchQuery,
  useGetAllocatedCustomerFullDetailsQuery,
  useGetAllocatedCustomerContactDetailsQuery,
  useGetCountAllocationsQuery,
  useGetAllocationQuotaQuery,
  useUpdateAllocationsQuotaMutation,
  useGetAllocationAvailabilityQuery,
  useUpdateAllocationAvailabilityMutation,
  useGetAllocationRequestQuery,
  useAcceptAllocationAvailabilityRequestMutation,
  useDeclineAllocationAvailabilityRequestMutation,
} = customersApi;
