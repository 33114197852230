import { isClient } from "./utils/nextjs";

export const AUTH_USER_COOKIE_KEY = "__p_token";
export const AUTH_GUEST_COOKIE_KEY = "__p_gutoken";

export const LANGUAGE_CHOICE_DEFAULT = "ro";
export const LANGUAGE_CHOICES = [
  {
    value: "ro",
    label: "Română",
  },
  // {
  //   value: "en",
  //   label: "Engleza",
  // },
];

export const GENDER_CHOICES = [
  {
    value: "",
    label: "Gen",
  },
  {
    value: "female",
    label: "Femeie",
  },
  {
    value: "male",
    label: "Bărbat",
  },
  {
    value: "other",
    label: "Alte",
  },
  {
    value: "prefer_not_to_say",
    label: "Prefer să nu spun",
  },
];

type TimezoneInfo = {
  value: string;
  label: string;
  searchValues?: string;
  frequent?: boolean;
};

export const TIMEZONE_CHOICES: TimezoneInfo[] = [
  {
    value: "Europe/Bucharest",
    label: "România",
    searchValues: "Romania Bucharest Bucuresti",
    frequent: true,
  },
  {
    value: "Europe/Rome",
    label: "Italia",
    searchValues: "Italia Rome Roma",
    frequent: true,
  },
  {
    value: "Europe/Madrid",
    label: "Spania",
    searchValues: "Spania Madrid Espana",
    frequent: true,
  },
  {
    value: "Europe/London",
    label: "Regatul Unit",
    searchValues: "United Kingdom London England Great Britain Anglia",
    frequent: true,
  },
  {
    value: "Europe/Berlin",
    label: "Germania",
    searchValues: "Deutschland Berlin Germany Germania",
    frequent: true,
  },
  {
    value: "Europe/Paris",
    label: "Franţa",
    searchValues: "Paris Franţa French Franceză",
    frequent: true,
  },
  {
    value: "Europe/Amsterdam",
    label: "Olanda",
    searchValues: "Netherlands Amsterdam Holland Olanda",
  },
  {
    value: "Europe/Brussels",
    label: "Belgia",
    searchValues: "Belgium Brussels Belgia",
  },
  {
    value: "Europe/Vienna",
    label: "Austria",
    searchValues: "Austria Vienna Viena",
  },
  {
    value: "Europe/Zurich",
    label: "Elveţia",
    searchValues: "Switzerland Zurich Elveţia",
  },
  {
    value: "Europe/Stockholm",
    label: "Suedia",
    searchValues: "Sweden Stockholm Suedia",
  },
  {
    value: "Europe/Oslo",
    label: "Norvegia",
    searchValues: "Norway Oslo Norvegia",
  },
  {
    value: "Europe/Copenhagen",
    label: "Danemarca",
    searchValues: "Denmark Copenhagen Danemarca Copenhaga",
  },
];

export const EXTENDED_TIMEZONE_CHOICES: TimezoneInfo[] = [
  {
    value: "Europe/Andorra",
    label: "Andorra",
    searchValues: "Andorra",
  },
  {
    value: "Europe/Tirane",
    label: "Albania",
    searchValues: "Albania Tirane",
  },
  {
    value: "Europe/Yerevan",
    label: "Armenia",
    searchValues: "Armenia Yerevan",
  },
  {
    value: "Europe/Minsk",
    label: "Belarus",
    searchValues: "Belarus Minsk",
  },
  {
    value: "Europe/Sofia",
    label: "Bulgaria",
    searchValues: "Bulgaria Sofia",
  },
  {
    value: "Europe/Zagreb",
    label: "Croatia",
    searchValues: "Croatia Zagreb",
  },
  {
    value: "Europe/Nicosia",
    label: "Cyprus",
    searchValues: "Cyprus Nicosia",
  },
  {
    value: "Europe/Prague",
    label: "Czech Republic",
    searchValues: "Czech Republic Prague",
  },
  {
    value: "Europe/Tallinn",
    label: "Estonia",
    searchValues: "Estonia Tallinn",
  },
  {
    value: "Europe/Helsinki",
    label: "Finland",
    searchValues: "Finland Helsinki",
  },
  {
    value: "Europe/Athens",
    label: "Greece",
    searchValues: "Greece Athens",
  },
  {
    value: "Europe/Budapest",
    label: "Hungary",
    searchValues: "Hungary Budapest",
  },
  {
    value: "Europe/Reykjavik",
    label: "Iceland",
    searchValues: "Iceland Reykjavik",
  },
  {
    value: "Europe/Dublin",
    label: "Ireland",
    searchValues: "Ireland Dublin",
  },
  {
    value: "Europe/Riga",
    label: "Latvia",
    searchValues: "Latvia Riga",
  },
  {
    value: "Europe/Vilnius",
    label: "Lithuania",
    searchValues: "Lithuania Vilnius",
  },
  {
    value: "Europe/Luxembourg",
    label: "Luxembourg",
    searchValues: "Luxembourg",
  },
  {
    value: "Europe/Valletta",
    label: "Malta",
    searchValues: "Malta Valletta",
  },
  {
    value: "Europe/Chisinau",
    label: "Moldova",
    searchValues: "Moldova Chisinau",
  },
  {
    value: "Europe/Monaco",
    label: "Monaco",
    searchValues: "Monaco",
  },
  {
    value: "Europe/Podgorica",
    label: "Montenegro",
    searchValues: "Montenegro Podgorica",
  },
  {
    value: "Europe/Skopje",
    label: "North Macedonia",
    searchValues: "North Macedonia Skopje",
  },
  {
    value: "Europe/Warsaw",
    label: "Poland",
    searchValues: "Poland Warsaw",
  },
  {
    value: "Europe/Lisbon",
    label: "Portugal",
    searchValues: "Portugal Lisbon",
  },
  {
    value: "Europe/Moscow",
    label: "Russia",
    searchValues: "Russia Moscow",
  },
  {
    value: "Europe/Belgrade",
    label: "Serbia",
    searchValues: "Serbia Belgrade",
  },
  {
    value: "Europe/Bratislava",
    label: "Slovakia",
    searchValues: "Slovakia Bratislava",
  },
  {
    value: "Europe/Ljubljana",
    label: "Slovenia",
    searchValues: "Slovenia Ljubljana",
  },
  {
    value: "Europe/Istanbul",
    label: "Turkey",
    searchValues: "Turkey Istanbul",
  },
  {
    value: "Europe/Kiev",
    label: "Ukraine",
    searchValues: "Ukraine Kiev",
  },
];

export const ALL_AVAILABLE_TIMEZONES_CHOICES: TimezoneInfo[] = [...TIMEZONE_CHOICES, ...EXTENDED_TIMEZONE_CHOICES];

if (isClient()) {
  const knownTimezones = new Set(ALL_AVAILABLE_TIMEZONES_CHOICES.map((t) => t.value));
  try {
    const timezones = window.Intl.supportedValuesOf("timeZone");
    for (const timezone of timezones) {
      if (!knownTimezones.has(timezone)) {
        ALL_AVAILABLE_TIMEZONES_CHOICES.push({
          value: timezone,
          label: timezone,
          searchValues: timezone,
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export const BOOKING_HOURS_MINUTES = [
  // 12 AM hours
  { value: "00:00", label: "00:00" },
  { value: "00:30", label: "00:30" },

  // 1 AM hours
  { value: "01:00", label: "01:00" },
  { value: "01:30", label: "01:30" },

  // 2 AM hours
  { value: "02:00", label: "02:00" },
  { value: "02:30", label: "02:30" },

  // 3 AM hours
  { value: "03:00", label: "03:00" },
  { value: "03:30", label: "03:30" },

  // 4 AM hours
  { value: "04:00", label: "04:00" },
  { value: "04:30", label: "04:30" },

  // 5 AM hours
  { value: "05:00", label: "05:00" },
  { value: "05:30", label: "05:30" },

  // 6 AM hours
  { value: "06:00", label: "06:00" },
  { value: "06:30", label: "06:30" },

  // 7 AM hours
  { value: "07:00", label: "07:00" },
  { value: "07:30", label: "07:30" },

  // 8 AM hours
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },

  // 9 AM hours
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },

  // 10 AM hours
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },

  // 11 AM hours
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },

  // 12 PM hours
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },

  // 1 PM hours
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },

  // 2 PM hours
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },

  // 3 PM hours
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },

  // 4 PM hours
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },

  // 5 PM hours
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },

  // 6 PM hours
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },

  // 7 PM hours
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },

  // 8 PM hours
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },

  // 9 PM hours
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },

  // 10 PM hours
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },

  // 11 PM hours
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" },
];

export const TIME_SLOTS = [
  // 12 AM hours
  { value: "00:00", label: "00:00" },
  { value: "00:30", label: "00:30" },

  // 1 AM hours
  { value: "01:00", label: "01:00" },
  { value: "01:30", label: "01:30" },

  // 2 AM hours
  { value: "02:00", label: "02:00" },
  { value: "02:30", label: "02:30" },

  // 3 AM hours
  { value: "03:00", label: "03:00" },
  { value: "03:30", label: "03:30" },

  // 4 AM hours
  { value: "04:00", label: "04:00" },
  { value: "04:30", label: "04:30" },

  // 5 AM hours
  { value: "05:00", label: "05:00" },
  { value: "05:30", label: "05:30" },

  // 6 AM hours
  { value: "06:00", label: "06:00" },
  { value: "06:30", label: "06:30" },

  // 7 AM hours
  { value: "07:00", label: "07:00" },
  { value: "07:30", label: "07:30" },

  // 8 AM hours
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },

  // 9 AM hours
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },

  // 10 AM hours
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },

  // 11 AM hours
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },

  // 12 PM hours
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },

  // 1 PM hours(Remember to start from 13 since it's technically PM)
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },

  // 2 PM hours
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },

  // 3 PM hours
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },

  // 4 PM hours
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },

  // 5 PM hours
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },

  // 6 PM hours
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },

  // 7 PM hours
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },

  // 8 PM hours
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },

  // 9 PM hours
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },

  // 10 PM hours
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },

  // 11 PM hours
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" },
];

export const BOOKING_LENGTH_MINUTES = [
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "50", label: "50" },
  { value: "60", label: "60" },
];

export const BOOKING_EXPIRY_MINUTES = [
  { value: "60", label: "1 oră" },
  { value: "180", label: "3 ore" },
  { value: "360", label: "6 ore" },
  { value: "720", label: "12 ore" },
  { value: "1440", label: "1 zi" },
  { value: "2880", label: "2 zile" },
  { value: "4320", label: "3 zile" },
  { value: "8640", label: "6 zile" },
];

export const BOOKING_MIN_TIME_BEFORE_START = [
  { value: "20", label: "20 minute" },
  { value: "30", label: "30 minute" },
  { value: "60", label: "1 oră" },
  { value: "120", label: "2 ore" },
  { value: "180", label: "3 ore" },
  { value: "360", label: "6 ore" },
  { value: "1440", label: "1 zi" },
  { value: "2880", label: "2 zile" },
  { value: "4320", label: "3 zile" },
  { value: "8640", label: "6 zile" },
];

export const BOOKING_BREAK_MINUTES = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "60", label: "60" },
];

export const ALLOCATION_SOURCE_TO_LABEL: Record<string, string> = {
  referral: "Recomandare",
  purchase: "Programare",
  email_campaign: "Campanie alocare gratuită",
  availability_request: "Cerere disponibilitate",
};
