import { createApi } from "@reduxjs/toolkit/query/react";
import { url } from "inspector";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: baseApiQuery(),
  tagTypes: [
    "PersonalInfo",
    "ContactInfo",
    "Summary",
    "Preferences",
    "NotificationPreferences",
    "PasswordStatus",
    "SiteDomains",
  ],
  endpoints: (builder) => ({
    checkToken: builder.query<any, string>({
      query: (token) => ({
        url: `/api/providers/me/ping`,
        headers: {
          "X-Authorization": `Bearer ${token}`,
        },
      }),
      providesTags: ["Summary"],
    }),
    refreshTokenIfNeeded: builder.query<any, void>({
      query: () => ({
        url: `/api/providers/me/ping?renew_token_if_needed=true`,
      }),
    }),
    checkTokenAndGetSummary: builder.query<any, { token: string; [key: string]: any }>({
      query: ({ token, ...rest }) => ({
        url: addQueryDictToUrl(`/api/providers/me/summary`, rest),
        headers: {
          "X-Authorization": `Bearer ${token}`,
        },
      }),
      providesTags: ["Summary"],
    }),
    getSummary: builder.query<any, void>({
      query: () => `/api/providers/me/summary`,
      providesTags: ["Summary"],
    }),
    getProviderInfo: builder.query<any, void>({
      query: () => `/api/providers/me/personal-info`,
      providesTags: ["PersonalInfo"],
    }),
    confirmEmail: builder.mutation<any, any>({
      query: ({ code }) => ({
        url: `/api/providers/auth/confirm-email`,
        method: "POST",
        body: { code },
      }),
    }),
    resendConfirmEmail: builder.mutation<any, void>({
      query: () => ({
        url: `/api/providers/auth/confirm-email-resend`,
        method: "POST",
      }),
    }),
    changeEmail: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/me/email-change`,
        method: "PUT",
        body: data,
      }),
    }),
    updatePassword: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/me/password-change`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["PasswordStatus"],
    }),
    getPasswordStatus: builder.query<any, void>({
      query: () => `/api/providers/me/password-status`,
      providesTags: ["PasswordStatus"],
    }),
    getApplicationPreferences: builder.query<any, void>({
      query: () => `/api/providers/me/preferences`,
      providesTags: ["Preferences"],
    }),
    updateApplicationPreferences: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/me/preferences`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Preferences", "Summary"],
    }),
    getNotificationPreferences: builder.query<any, void>({
      query: () => `/api/providers/me/notification-preferences`,
      providesTags: ["NotificationPreferences"],
    }),
    updateNotificationPreferences: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers/me/notification-preferences`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["NotificationPreferences", "Summary"],
    }),
    getSiteDomains: builder.query<any, void>({
      query: () => `/api/providers/me/site-domains`,
      providesTags: ["SiteDomains"],
    }),
  }),
});
export const {
  useCheckTokenQuery,
  useCheckTokenAndGetSummaryQuery,
  useGetProviderInfoQuery,
  useConfirmEmailMutation,
  useResendConfirmEmailMutation,
  useChangeEmailMutation,
  useUpdatePasswordMutation,
  useGetApplicationPreferencesQuery,
  useUpdateApplicationPreferencesMutation,
  useGetNotificationPreferencesQuery,
  useUpdateNotificationPreferencesMutation,
  useGetPasswordStatusQuery,
  useRefreshTokenIfNeededQuery,
  useGetSiteDomainsQuery,
} = accountApi;
